<template>
  <div style="background-color:#f8f8f8;height:100vh">
    <router-view></router-view>
  </div>
</template>


<script>
import { isMobile } from "../../views/components/fnc.js";

export default {
  computed: {
    _mobile: function() {
      var _mobile = this.$vssWidth < 1024 ? true : isMobile();
      return _mobile;
    }
  }
};
</script>

<style>
</style>